import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '~/core/ui/Button'
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from '~/core/ui/Popover'
import { ISelectOption, SelectOption } from '~/core/ui/Select'
import { TextButton } from '~/core/ui/TextButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { IAIWriterJobType } from '~/lib/features/jobs/types'
import { TONE_LIST } from '~/lib/features/jobs/utilities/enum'

const AIWriterJobGenerateContent: FC<{
  textGenerate?: string
  onSubmitAIWriter?: () => void
  onRegenerate?: () => void
  isReplaceAIWriter?: boolean
  valueAIWriter?: IAIWriterJobType
  setValueAIWriter?: (value: { language?: string; toneType?: string }) => void
  onSubmitAddJD?: () => void
}> = ({
  textGenerate,
  onSubmitAIWriter,
  onRegenerate,
  isReplaceAIWriter,
  valueAIWriter,
  setValueAIWriter,
  onSubmitAddJD
}) => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    if (!textGenerate && onSubmitAIWriter) {
      onSubmitAIWriter()
    }
  }, [])
  const wrapRef = useRef(null)
  return (
    <div>
      <div
        className="mt-3 max-h-[286px] overflow-y-scroll text-sm text-gray-900"
        dangerouslySetInnerHTML={{ __html: textGenerate || '' }}
      />

      <div className="mt-3 flex items-center justify-between">
        <div ref={wrapRef}>
          <Popover>
            <PopoverTrigger asChild>
              <TextButton
                label={t(`form:${valueAIWriter?.toneType}`)}
                size="md"
                underline={false}
                iconMenus="ChevronDown"
                icon="trailing"
                classNameText="test-sm font-medium"
              />
            </PopoverTrigger>

            <PopoverPortal>
              <PopoverContent
                onOpenAutoFocus={(e) => e.preventDefault()}
                side="top"
                align="start"
                className="w-[190px] translate-x-[18px] space-y-1 p-1">
                {TONE_LIST.map((item: ISelectOption, index: number) => (
                  <div
                    className="hover:bg-gray-50"
                    key={index}
                    onClick={(e) => {
                      setValueAIWriter &&
                        setValueAIWriter({
                          ...valueAIWriter,
                          toneType: item.value
                        })
                    }}>
                    <SelectOption
                      option="radio"
                      size="sm"
                      data={{
                        ...item,
                        supportingObj: {
                          name: t(`form:${item?.supportingObj?.name}`)
                        }
                      }}
                      isSelected={valueAIWriter?.toneType === item.value}
                      isFocused={valueAIWriter?.toneType === item.value}
                      isOption={true}
                      isHeading={false}
                    />
                  </div>
                ))}
              </PopoverContent>
            </PopoverPortal>
          </Popover>
        </div>
        <div className="flex">
          <Button
            label={`${t('button:regenerate')}`}
            type="secondary"
            size="sm"
            htmlType="button"
            className="mr-3"
            onClick={onSubmitAIWriter}
          />
          <Button
            label={
              isReplaceAIWriter
                ? `${t('button:replace')}`
                : `${t('button:insert')}`
            }
            type="primary"
            size="sm"
            htmlType="button"
            onClick={onSubmitAddJD}
          />
        </div>
      </div>
    </div>
  )
}

export default AIWriterJobGenerateContent
