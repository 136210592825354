'use client'

import { cn, getDisabledSelectedCondition } from '~/core/ui/utils'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { cva } from 'class-variance-authority'
import { forwardRef, ReactNode, useEffect, useState } from 'react'

const radioRootVariants = cva('flex', {
  variants: {
    orientation: {
      vertical: 'flex-col space-y-4',
      horizontal: 'space-x-4'
    }
  },
  defaultVariants: {
    orientation: 'vertical'
  }
})

const radioWrapperVariants = cva('relative', {
  variants: {
    variant: {
      onlyText: 'flex items-center',
      textWithDescription: 'inline-flex'
    },
    size: {
      md: 'space-x-3',
      sm: 'space-x-2'
    },
    disabled: {
      default: '',
      disabled: 'pointer-events-none'
    }
  },
  defaultVariants: {
    variant: 'onlyText',
    size: 'md',
    disabled: 'default'
  }
})

const radioBoxVariants = cva('flex items-center justify-center rounded-full', {
  variants: {
    variant: {
      onlyText: '',
      textWithDescription: 'mt-[3px]'
    },
    size: {
      md: 'h-5 w-5 min-w-[20px] min-h-[20px]',
      sm: 'h-4 w-4 min-w-[16px] min-h-[16px]'
    },
    disabled: {
      default: '',
      disabled: 'pointer-events-none'
    },
    checkedCondition: {
      noDisabledSelected:
        'border border-solid focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700 border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:bg-gray-800',
      noDisabledWithSelected:
        'focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700 bg-primary-400',
      disabledNoSelect:
        'border border-solid focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700 border-gray-200 bg-gray-50 dark:border-gray-700 dark:bg-gray-800',
      disabledWithSelect:
        'focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700 bg-primary-200 dark:bg-primary-800'
    }
  },
  defaultVariants: {
    variant: 'onlyText',
    size: 'md',
    disabled: 'default',
    checkedCondition: 'noDisabledSelected'
  }
})

interface RadioProps {
  name?: string
  size?: 'sm' | 'md'
  source?: Array<{
    value?: string
    text?: string | ReactNode
    supportingText?: string
    description?: string
    reactNode?: ReactNode
  }>

  value?: string
  onValueChange?: (value: string) => void
  isDisabled?: boolean
  required?: boolean
  orientation?: 'horizontal' | 'vertical'
  className?: string
  radioItemClassName?: string
  rootClassName?: string
  textClassName?: string
}

const Radio = forwardRef<React.ElementRef<typeof RadioGroup.Root>, RadioProps>(
  (props, ref) => {
    const {
      name = 'view-radio',
      size = 'md',

      value = '',
      onValueChange = undefined,
      isDisabled = false,
      required = false,
      orientation = 'vertical',
      className = '',
      radioItemClassName = '',
      rootClassName = '',
      textClassName = '',
      source = []
    } = props
    const [valueState, setValueState] = useState(value)
    useEffect(() => {
      setValueState(value)
    }, [value])

    return (
      <RadioGroup.Root
        ref={ref}
        className={cn(
          radioRootVariants({
            orientation,
            className: rootClassName || className
          })
        )}
        value={valueState}
        onValueChange={(value) => {
          setValueState(value)
          if (onValueChange) {
            onValueChange(value)
          }
        }}
        disabled={isDisabled}
        required={required}
        aria-label={name}
        orientation={orientation}>
        {source.map((session, index) => {
          const fontSizeLabel = size === 'md' ? 'text-base' : 'text-sm'
          return (
            <div
              className={cn(
                radioWrapperVariants({
                  size,
                  variant:
                    session.text && !session.description
                      ? 'onlyText'
                      : 'textWithDescription',
                  disabled: isDisabled ? 'disabled' : 'default',
                  className: radioItemClassName || className
                })
              )}
              key={index}>
              <RadioGroup.Item
                className={cn(
                  radioBoxVariants({
                    size,
                    variant:
                      session.text && !session.description
                        ? 'onlyText'
                        : 'textWithDescription',
                    disabled: isDisabled ? 'disabled' : 'default',
                    checkedCondition: getDisabledSelectedCondition({
                      isDisabled,
                      isSelected: session.value === valueState
                    }),
                    className
                  })
                )}
                value={session.value || `radio-${index}`}>
                <RadioGroup.Indicator
                  className={`after:content['""'] relative flex h-full w-full items-center justify-center after:block after:rounded-full after:bg-white ${
                    isDisabled ? 'dark:after:bg-gray-400' : ''
                  } ${
                    size === 'md'
                      ? 'after:h-[7.5px] after:w-[7.5px]'
                      : 'after:h-[6px] after:w-[6px]'
                  }`}
                />
              </RadioGroup.Item>

              {session.text && !session.description ? (
                <div className="flex items-center space-x-1">
                  <span
                    className={cn(`${fontSizeLabel} text-gray-900 dark:text-gray-300`, textClassName)}>
                    {session.text}
                  </span>
                  {session.supportingText ? (
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {session.supportingText}
                    </p>
                  ) : null}
                </div>
              ) : null}

              {session.text && session.description ? (
                <div className="space-y-1">
                  {session.text ? (
                    <div className="flex items-center space-x-1">
                      <span
                        className={`${fontSizeLabel} font-medium text-gray-900 dark:text-gray-200`}>
                        {session.text}
                      </span>

                      {session.supportingText ? (
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                          {session.supportingText}
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                  {session.description ? (
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {session.description}
                    </p>
                  ) : null}
                </div>
              ) : null}
              {session.reactNode ? session.reactNode : null}
            </div>
          )
        })}
      </RadioGroup.Root>
    )
  }
)

Radio.displayName = 'Radio'

export { Radio }
export type { RadioProps }
