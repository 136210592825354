import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '~/core/ui/Button'

const AIWriterGenerateContent: FC<{
  textGenerate?: string
  onSubmitAddSummary?: () => void
  onRegenerate?: () => void
  isReplaceAIWriter?: boolean
}> = ({
  textGenerate,
  onSubmitAddSummary,
  onRegenerate,
  isReplaceAIWriter
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="mb-3 text-sm text-gray-500">
        {isReplaceAIWriter && `${t('form:tooltip_replace_ai_writer')}`}
      </div>
      <div
        className="max-h-[286px] overflow-y-scroll text-sm text-gray-900"
        dangerouslySetInnerHTML={{ __html: textGenerate || '' }}
      />

      <div className="mt-3 flex justify-end">
        <Button
          label={`${t('button:regenerate')}`}
          type="secondary"
          size="sm"
          htmlType="button"
          className="mr-3"
          onClick={onRegenerate}
        />
        <Button
          label={
            isReplaceAIWriter
              ? `${t('button:replace')}`
              : `${t('button:insert')}`
          }
          type="primary"
          size="sm"
          htmlType="button"
          onClick={onSubmitAddSummary}
        />
      </div>
    </div>
  )
}

export default AIWriterGenerateContent
