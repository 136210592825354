import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import configuration from '~/configuration'
import { Drawer } from '~/core/ui/Drawer'
import Empty from '~/core/ui/Empty'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { Skeleton } from '~/core/ui/Skeleton'
import { Tabs, TabsList, TabsTrigger, TabsTriggerView } from '~/core/ui/Tabs'
import { TypographyText } from '~/core/ui/Text'
import { Toggle } from '~/core/ui/Toggle'
import { cn, debounce } from '~/core/ui/utils'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { limitedMemberCanAction } from '~/core/utilities/permission'
import usePermissionCandidate from '~/lib/features/permissions/hooks/use-permission-candidate'
import usePermissionCompany from '~/lib/features/permissions/hooks/use-permission-company'
import useTasksManagement from '~/lib/features/tasks/hooks/use-tasks-management'
import { mappingTaskList } from '~/lib/features/tasks/mapping/tasks-listing-mapping'
import { TaskFormInActiveType, TaskItemType } from '~/lib/features/tasks/types'
import { TAB_KEYS, TASK_STATUS } from '~/lib/features/tasks/utilities/enum'
import useBoundStore from '~/lib/store'
import InfiniteScroll from '../List/InfiniteScroll'
import AddTaskForm from './AddTaskForm'
import TaskCard from './TaskCard'

const TaskUserContainer: FC = () => {
  const { t } = useTranslation()
  const taskListRef = useRef<HTMLDivElement | null>(null)
  const [formInActive, setFormInActive] = useState<TaskFormInActiveType>()
  const [isDebounce, setDebounce] = useState(false)
  const setToast = useBoundStore((state) => state.setToast)
  const openDrawer = useBoundStore((state) => state.openTaskDrawer)
  const setOpenDrawer = useBoundStore((state) => state.setOpenTaskDrawer)
  const setTaskCount = useBoundStore((state) => state.setTaskCount)
  const { setRefetchMyList } = useBoundStore()
  const calcHeight = 'h-[calc(100vh_-_100px)]'
  const user = useBoundStore((state) => state.user)
  const currentRole = useBoundStore((state) => state.currentRole)
  const limitedMember = limitedMemberCanAction(currentRole?.code)

  const { actionProfileApplicantTask } = usePermissionCandidate({})
  const { actionCompanyTask } = usePermissionCompany()

  const { taskPaging, filterControl, action } = useTasksManagement()
  const groupingData = useMemo(() => {
    return taskPaging?.data?.pages
      ? mappingTaskList(taskPaging?.data?.pages)
      : undefined
  }, [taskPaging?.data])

  useEffect(() => {
    if (
      taskPaging?.data?.pages?.[0]?.tasksList?.metadata?.totalCount !==
        undefined &&
      !filterControl.value.filterBy
    ) {
      setTaskCount(
        Number(taskPaging.data.pages?.[0].tasksList?.metadata?.totalCount)
      )
    }
  }, [setTaskCount, taskPaging, filterControl.value.filterBy])

  const callbackRefetch = useCallback(() => {
    taskPaging.refetch()
  }, [taskPaging])

  const onFilterCompleted = useCallback(
    (value: boolean) => {
      if (isDebounce === false) {
        filterControl.onChange({
          tab: !!filterControl?.value?.tab
            ? filterControl.value.tab
            : TAB_KEYS[0].value,
          filterBy: value ? 'completed' : null
        })
      } else {
        setToast({
          open: true,
          type: 'info',
          title: t('notification:task:pleaseWaitMoment'),
          classNameConfig: {
            viewport: 'mb-[48px]'
          }
        })
      }
    },
    [filterControl?.value]
  )

  const onChangeTab = useCallback((value: string) => {
    filterControl.onChange({
      tab: value || TAB_KEYS[0].value,
      filterBy: null
    })
  }, [])

  let checkTaskList = useRef<Array<{ checked: boolean; taskId: number }>>([])
  const onCheckTaskDebounce = useCallback(
    debounce(async () => {
      if (checkTaskList?.current?.length) {
        checkTaskList.current.forEach(async (params) => {
          await onCheckTask(params)
        })
        setDebounce(false)
        setTimeout(() => {
          callbackRefetch()
          checkTaskList.current = []
        }, 500)
      }
    }, 2500),
    []
  )

  const onCheckTask = useCallback<
    (params: { checked: boolean; taskId: number }) => void
  >(
    ({ checked, taskId }) => {
      if (action.updateTaskAction.updatingTask) return Promise.resolve()

      return action.updateTaskAction
        .updateTaskStatus({
          id: taskId,
          status: checked ? TASK_STATUS.done : TASK_STATUS.undone
        })
        .then((result) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.tasks.list,
              setToast
            })
          }

          setToast({
            open: true,
            type: 'success',
            title: checked
              ? t('notification:task:taskDone')
              : t('notification:task:taskMovedToDoList'),
            classNameConfig: {
              viewport: 'mb-[48px]'
            }
          })
        })
    },
    [action.updateTaskAction, setToast, t]
  )

  // ----- RENDERED ----- //

  const renderTaskGroupListing = ({
    groupingTime
  }: {
    groupingTime: 'today' | 'tomorrow' | 'upcoming' | 'overdue'
  }) => {
    return (
      groupingData &&
      groupingData[groupingTime].length > 0 && (
        <div
          className={cn(
            filterControl.value?.filterBy !== 'completed'
              ? 'mb-6 last:mb-0'
              : ''
          )}>
          {filterControl.value?.filterBy !== 'completed' && (
            <TypographyText className="mb-4 text-sm font-medium text-gray-700">
              {t(`task:${groupingTime}`)}
            </TypographyText>
          )}
          <div className="space-y-4">
            {groupingData[groupingTime].map((task: TaskItemType) => (
              <div key={task.id}>
                <TaskCard
                  configShow={{
                    dueDate: true,
                    assignees: true,
                    isDrawer: true
                  }}
                  ownedAction={{
                    delete: [
                      actionProfileApplicantTask.owned_delete,
                      actionCompanyTask.owned_delete
                    ].includes(true),
                    edit: [
                      actionProfileApplicantTask.owned_update,
                      actionCompanyTask.owned_update
                    ].includes(true)
                  }}
                  task={task}
                  formInActive={formInActive}
                  setFormInActive={setFormInActive}
                  action={action}
                  refetchList={callbackRefetch}
                  showAllAssigneeList={limitedMember}
                  onCheckTask={(e) => {
                    if (isDebounce === false) {
                      setDebounce(true)
                    }
                    checkTaskList?.current?.push(e)
                    onCheckTaskDebounce()
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )
    )
  }

  const renderedTabs = () => {
    return (
      <div className="flex items-start justify-between border-b border-b-gray-100 px-6 pt-5">
        <Tabs
          defaultValue={filterControl.value?.tab || TAB_KEYS[0].value}
          onValueChange={onChangeTab}>
          <TabsList size="sm">
            {TAB_KEYS.map((tab, index) => (
              <TabsTrigger key={`tab-${index}`} size="sm" value={tab.value}>
                <TabsTriggerView
                  size="sm"
                  session={{
                    value: tab.value,
                    label: `${t(`task:tabs:${tab.value}`)}`,
                    count:
                      taskPaging?.data?.pages?.[0]?.tasksList?.metadata
                        .totalCount
                  }}
                />
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>

        <Toggle
          isDebounce={isDebounce}
          isChecked={filterControl.value?.filterBy === 'completed'}
          name="view-switch"
          onCheckedChange={onFilterCompleted}
          size="sm"
          text={`${t('task:completed')}`}
          toggle="leading"
        />
      </div>
    )
  }

  const renderedEmpty = () => (
    <>
      {taskPaging?.data?.pages?.[0]?.tasksList?.metadata?.totalCount === 0 ? (
        <div
          className={cn(
            'flex flex-1 items-center justify-center px-6 pb-6 pt-4',
            calcHeight
          )}>
          <div className="px-0.5 pt-0.5">
            {filterControl.value?.filterBy === 'completed' ? (
              <Empty
                type="empty-search"
                title={`${t('task:list:no_result:title')}`}
                description={`${t('task:list:no_result:description')}`}
                buttonTitle={`${t('task:list:no_result:show_task_button')}`}
                onClick={() => {
                  filterControl.onChange({
                    tab: filterControl.value?.tab
                      ? filterControl.value.tab
                      : TAB_KEYS[0].value,
                    filterBy: null
                  })
                }}
              />
            ) : (
              <Empty
                type="empty-data"
                title={`${t('task:list:empty:title')}`}
                description={`${t('task:list:empty:description')}`}
                buttonTitle={`${t('task:list:empty:create_button')}`}
                onClick={() =>
                  setFormInActive({ type: 'add', taskId: undefined })
                }
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  )

  const renderedContent = () => (
    <div className="flex min-h-screen flex-col justify-between">
      <div className="flex-1">
        {renderedTabs()}
        {renderedEmpty()}

        {groupingData &&
        taskPaging?.data?.pages?.[0]?.tasksList?.metadata?.totalCount !== 0 ? (
          <ScrollArea
            scrollRef={taskListRef}
            className={cn('flex-1 px-6 pb-6 pt-4', calcHeight)}>
            <div className="px-0.5 pt-0.5">
              <InfiniteScroll
                className="space-y-4"
                wrapperRef={taskListRef}
                loadData={() => taskPaging.fetchNextPage()}
                haveNext={!!taskPaging.hasNextPage}
                skeletonElement={
                  <div className="mb-6">
                    <Skeleton className="mb-4 h-5 w-full rounded" />
                    {[1, 2, 3, 4].map((item) => (
                      <div key={`task-skeleton-${item}`} className="mt-4">
                        <Skeleton className="mb-1.5 h-5 w-full rounded" />
                        <Skeleton className="h-[18px] w-full rounded" />
                      </div>
                    ))}
                  </div>
                }
                isShowEndLabel={false}>
                {renderTaskGroupListing({ groupingTime: 'overdue' })}
                {renderTaskGroupListing({ groupingTime: 'today' })}
                {renderTaskGroupListing({ groupingTime: 'tomorrow' })}
                {renderTaskGroupListing({ groupingTime: 'upcoming' })}

                {!taskPaging.hasNextPage &&
                  (taskPaging?.data?.pages?.[0]?.tasksList?.metadata
                    ?.totalCount || 0) > configuration.defaultPageSize && (
                    <TypographyText className="mt-4 text-center text-base text-gray-600">
                      {t('careers:thatAllJobs')}
                    </TypographyText>
                  )}
              </InfiniteScroll>
            </div>
          </ScrollArea>
        ) : null}
      </div>

      <div className="absolute bottom-px left-0 right-0">
        <AddTaskForm
          autoFocus
          formInActive={formInActive}
          setFormInActive={setFormInActive}
          callback={() => {
            callbackRefetch()
          }}
        />
      </div>
    </div>
  )
  const drawerContainerRef = useRef<HTMLDivElement>(null)
  return (
    <TaskContext.Provider value={{ drawerContainerRef }}>
      <Drawer
        contentRef={drawerContainerRef}
        open={openDrawer}
        onClose={(e) => {
          setOpenDrawer(false)
          setRefetchMyList(true)
        }}
        onEscapeKeyDown={() => {
          setOpenDrawer(false)
          setRefetchMyList(true)
        }}>
        {renderedContent()}
      </Drawer>
    </TaskContext.Provider>
  )
}

export const useGetTaskDrawerRef = () => {
  const taskContext = useContext(TaskContext)
  return taskContext.drawerContainerRef?.current
}

const TaskContext = createContext<{
  drawerContainerRef?: React.RefObject<HTMLDivElement>
}>({})

export default withQueryClientProvider(TaskUserContainer)
