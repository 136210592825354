import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStaticData from 'src/hooks/data/use-static-data'
import { Button } from '~/core/ui/Button'
import { CloseButton } from '~/core/ui/CloseButton'
import IconWrapper from '~/core/ui/IconWrapper'
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from '~/core/ui/Popover'
import { ISelectOption, SelectOption } from '~/core/ui/Select'
import { IAIWriterJobType } from '~/lib/features/jobs/types'
import AIWriterJobGenerateContent from './AIWriterJobGenerateContent'

const AIWriterJobWrapper: FC<{
  toggleAIWriter?: () => void
  loadingGenerate?: boolean
  textGenerate?: string
  onSubmitAIWriter?: () => Promise<void>
  onRegenerate?: () => void
  isReplaceAIWriter?: boolean
  valueAIWriter?: IAIWriterJobType
  setValueAIWriter?: (value: { language?: string; toneType?: string }) => void
  onSubmitAddJD?: () => void
}> = ({
  toggleAIWriter,
  loadingGenerate,
  textGenerate,
  onSubmitAIWriter,
  onRegenerate,
  isReplaceAIWriter,
  valueAIWriter,
  setValueAIWriter,
  onSubmitAddJD
}) => {
  const { t, i18n } = useTranslation()
  const [openSelect, setOpenSelect] = useState(false)
  const languageCollection = useStaticData({
    keyName: 'languages',
    locale: i18n.language
  })
  const languageList = languageCollection
    .map((item: { language: string; code: string }) => {
      return {
        value: item.code,
        supportingObj: {
          name: item.language
        }
      }
    })
    .filter((item: ISelectOption) => ['en', 'ja'].includes(item.value))

  return (
    <div className="px-4 py-3">
      <div>
        <div className="flex justify-between">
          <div className="text-lg font-semibold text-gray-900">
            {isReplaceAIWriter
              ? `${t('form:improve_AI')}`
              : `${t('form:ai_writer')}`}
          </div>
          <div className="flex items-center">
            <Popover
              open={openSelect}
              onOpenChange={(value) => setOpenSelect(value)}>
              <PopoverTrigger asChild>
                <div>
                  <Button
                    type="tertiary"
                    size="xs"
                    iconMenus="ChevronDown"
                    icon="trailing"
                    label={
                      languageList.find(
                        (item: ISelectOption) =>
                          item.value === valueAIWriter?.language
                      )?.supportingObj?.name
                    }
                  />
                </div>
              </PopoverTrigger>

              <PopoverPortal>
                <PopoverContent
                  align="end"
                  sideOffset={10}
                  className="w-[132px] p-1">
                  {languageList.map((item: ISelectOption, index: string) => (
                    <div
                      className="hover:bg-gray-50"
                      key={index}
                      onClick={(e) => {
                        setValueAIWriter &&
                          setValueAIWriter({
                            ...valueAIWriter,
                            language: item.value
                          })
                      }}>
                      <SelectOption
                        option="radio"
                        size="sm"
                        data={{
                          ...item
                        }}
                        isSelected={valueAIWriter?.language === item.value}
                        isFocused={valueAIWriter?.language === item.value}
                        isOption={true}
                        isHeading={false}
                      />
                    </div>
                  ))}
                </PopoverContent>
              </PopoverPortal>
            </Popover>
            <CloseButton
              iconClassName="w-4 h-4 flex items-start ml-2"
              onClick={toggleAIWriter}
            />
          </div>
        </div>
      </div>
      {loadingGenerate ? (
        <div className="mt-3 flex items-center">
          <IconWrapper
            className="animate-spin text-gray-500 dark:text-gray-400"
            name="Loader2"
            size={16}
          />
          <div className="ml-1 text-sm text-gray-500">
            {t('form:generating_loading')}
          </div>
        </div>
      ) : (
        <AIWriterJobGenerateContent
          valueAIWriter={valueAIWriter}
          textGenerate={textGenerate}
          setValueAIWriter={setValueAIWriter}
          onRegenerate={onRegenerate}
          onSubmitAIWriter={onSubmitAIWriter}
          isReplaceAIWriter={isReplaceAIWriter}
          onSubmitAddJD={onSubmitAddJD}
        />
      )}
    </div>
  )
}

export default AIWriterJobWrapper
