'use client'

import { Editor as CoreEditor } from '@tiptap/core'
// import Bold from '@tiptap/extension-bold'
import CharacterCount from '@tiptap/extension-character-count'
import { Color } from '@tiptap/extension-color'
// import Document from '@tiptap/extension-document'
// import Dropcursor from '@tiptap/extension-dropcursor'
// import Gapcursor from '@tiptap/extension-gapcursor'
import Highlight from '@tiptap/extension-highlight'
// import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
// import { Node } from '@tiptap/core'
// import ListItem from '@tiptap/extension-list-item'
import Mention from '@tiptap/extension-mention'
// import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
// import Text from '@tiptap/extension-text'
import Blockquote from '@tiptap/extension-blockquote'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import {
  BubbleMenu,
  EditorContent,
  ReactRenderer,
  useEditor
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { cva } from 'class-variance-authority'
import {
  ForwardRefExoticComponent,
  MouseEventHandler,
  ReactNode,
  RefAttributes,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useTranslation } from 'react-i18next'
import tippy from 'tippy.js'
import AIWriterForm from '~/components/Candidates/Profile/components/AIWriter/AIWriterForm'
import AIWriterWrapper from '~/components/Candidates/Profile/components/AIWriter/AIWriterWrapper'
import { ComboboxSelectProps } from '~/core/ui/ComboboxSelect'
import IconWrapper from '~/core/ui/IconWrapper'
import { ISelectOption, SelectOption } from '~/core/ui/Select'
import {
  cn,
  debounce,
  trimFirstContentBreakLine,
  uuidV4
} from '~/core/ui/utils'
import { IAIWriterForm } from '~/lib/features/candidates/types'
import { IFormAction } from './Form'
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from './Popover'
import { TextButton } from './TextButton'
import { Tooltip } from './Tooltip'
import { LOCAL, CLOUD, RESUME } from '~/core/constants/enum'
import AIWriterJobWrapper from '~/components/Candidates/Profile/components/AIWriterJob/AIWriterJobWrapper'
import { IAIWriterJobType } from '~/lib/features/jobs/types'

const editorVariants = cva(
  'relative w-full max-w-full rounded outline-none px-3 prose prose-p:!my-0 dark:prose-invert focus:outline-none',
  {
    variants: {
      variant: {
        default: 'border border-solid',
        unstyled: ''
      },
      variantBorder: {
        default: 'border-gray-300 dark:border-gray-600',
        'no-default': 'border-gray-200 dark:border-gray-700',
        none: ''
      },
      size: {
        md: 'prose-base',
        sm: 'prose-sm'
      },
      destructive: {
        none: '',
        default:
          'focus:border-primary-300 focus:shadow-select dark:focus:border-primary-700 dark:focus:shadow-dark-select',
        destructive:
          'border-red-300 focus:shadow-error dark:focus:shadow-dark-error dark:border-red-700 dark:bg-gray-900'
      },
      disabled: {
        default: 'dark:bg-gray-900',
        disabled: 'pointer-events-none bg-gray-50 dark:bg-gray-800'
      },
      mode: {
        bubble: 'pt-3 pb-[22px]',
        top: 'pt-[46px] pb-[22px]',
        bottom: 'pt-3 mb-[46px]'
      }
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
      disabled: 'default',
      mode: 'bubble',
      variantBorder: 'none'
    }
  }
)

const editorMenuVariants = cva(
  'h-[38px] bg-white z-10 rounded-t absolute left-[1px] right-[1px] flex items-center px-3 space-x-4',
  {
    variants: {
      variant: {
        default: 'border-gray-300 dark:border-gray-600',
        'no-default': 'border-gray-200 dark:border-gray-700',
        none: ''
      },
      disabled: {
        default: '',
        disabled: 'pointer-events-none'
      },
      toolbarPosition: {
        top: 'top-[1px] border-b border-solid',
        bottom: '-bottom-[46px]'
      }
    },
    defaultVariants: {
      variant: 'none',
      disabled: 'default',
      toolbarPosition: 'top'
    }
  }
)

const editorBubbleMenuVariants = cva(
  'h-[38px] z-10 rounded shadow-editor bg-white absolute -bottom-full left-0 border-b border-solid flex items-center px-3 space-x-4',
  {
    variants: {
      variant: {
        default: 'border-gray-300 dark:border-gray-600',
        'no-default': 'border-gray-200 dark:border-gray-700',
        none: ''
      },
      disabled: {
        default: '',
        disabled: 'pointer-events-none'
      }
    },
    defaultVariants: {
      variant: 'none',
      disabled: 'default'
    }
  }
)

const editorPlaceholderVariants = cva(
  'before:text-gray-600 before:dark:text-gray-400 before:content-[attr(data-placeholder)] before:float-left before:h-0 before:pointer-events-none',
  {
    variants: {
      size: {
        md: 'text-base',
        sm: 'text-sm'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)

type RichEditorSizeProps = 'md' | 'sm'

interface ExtraDropdownMenuEditorProps {
  component: {
    label: ReactNode | ((isFocus: boolean) => ReactNode)
    icon: ReactNode
  }
  source: Array<ISelectOption>
  onClick: (params: { param: ISelectOption; editor: CoreEditor }) => void
}

type IAttachmentsFile = {
  id?: string
  url?: string
  name?: string
  status?: 'pending' | 'error' | 'upload'
  statusDescription?: string
  file?: File
  selected?: boolean
  type?: string
  size?: number
}

type IAttachmentsFiles = Array<IAttachmentsFile>

type IAttachment = {
  show: boolean
  showPosition?: 'menuToolbar'
  itemPerRow?: string
  list?: IAttachmentsFiles
  acceptedFiles: string
  classNameItem?: string
  fileChange?: (event?: FileList | never[] | null) => void
  onDelete?: (params: { id?: string; index: number }) => void
  type?: string
  label?: string
  onClick?: () => void
}

interface IExtraToolbar {
  attachments?: IAttachment
  attachmentsList?: IAttachment[]
  expand?: {
    show: boolean
    isActive?: boolean
    onClick?: () => void
  }
  mentions?: {
    show: boolean
    suggestion: {
      component: ForwardRefExoticComponent<
        ComboboxSelectProps & RefAttributes<{}>
      >
      onSearch?: (query: string) => void
      componentProps?: ComboboxSelectProps
      componentPropsEditor?: ComboboxSelectProps
    }
  }
  AIWriter?: {
    show?: boolean
    isReplace?: boolean
    onClick?: () => void
    setShow?: () => void
    onSubmitAIWriter?: (
      data: IAIWriterForm,
      formAction: IFormAction
    ) => Promise<void>
    loadingGenerate?: boolean
    textGenerate?: string
    defaultValue: IAIWriterForm
    onRegenerate?: () => void
    onSubmitAddSummary?: () => void
    modal?: boolean
  }
  AIWriterJob?: {
    show?: boolean
    isReplace?: boolean
    onClick?: () => void
    setShow?: () => void
    loadingGenerate?: boolean
    textGenerate?: string
    valueAIWriter?: IAIWriterJobType
    setValueAIWriter?: (value: { language?: string; formal?: string }) => void
    onRegenerate?: () => void
    onSubmitAIWriter?: () => Promise<void>
    onSubmitAddJD?: () => void
    modal?: boolean
    isDisabled?: boolean
  }
}
interface RichEditorProps {
  toolbarPosition?: 'top' | 'bottom'
  variant?: 'default' | 'unstyled'
  getElementAppendById?: string
  content?: string
  onChange?: (editor: string) => void
  isDisabled?: boolean
  className?: string
  classNameWrapper?: string
  size?: RichEditorSizeProps
  destructive?: boolean
  placeholder?: string
  limit?: number
  showCount?: boolean
  extraDropdownMenuEditor?: ExtraDropdownMenuEditorProps
  bubble?: boolean
  editorRef?: (editor: CoreEditor) => void
  showToolbar?: boolean
  extraToolbar?: IExtraToolbar
  autoFocus?: boolean
  editorMenuClassName?: string
  isNoDebounceOnChange?: boolean
  toggleAIWriter?: () => void
  openAIWriter?: boolean
  extraSelectTemplateButton?: ReactNode
}

const MenuEditor = ({
  getElementAppendById,
  editor,
  extraDropdownMenuEditor,
  extraToolbar,
  toggleAIWriter,
  openAIWriter,
  extraSelectTemplateButton
}: {
  editor: CoreEditor
  extraDropdownMenuEditor?: ExtraDropdownMenuEditorProps
  extraToolbar?: IExtraToolbar
  getElementAppendById?: string
  toggleAIWriter?: () => void
  openAIWriter?: boolean
  extraSelectTemplateButton?: ReactNode
}) => {
  const { t } = useTranslation()
  const mentionParentRef = useRef(null)
  const [toggleOpenHeading, setToggleOpenHeading] = useState(false)
  const [toggleOpenActionDropdown, setToggleOpenActionDropdown] =
    useState(false)

  const [toggleOpenExtraDropdown, setToggleOpenExtraDropdown] = useState(false)
  const [toggleOpenAttachmentDropdown, setToggleOpenAttachmentDropdown] =
    useState(false)

  const MentionComponent = extraToolbar?.mentions?.suggestion?.component
  const refExtraDropdown = useOnclickOutside(
    () => {
      setToggleOpenExtraDropdown(false)
    },
    {
      ignoreClass: 'my-ignore-extra-dropdown'
    }
  )

  const refExtraAttachmentDropdown = useOnclickOutside(
    () => {
      setToggleOpenAttachmentDropdown(false)
    },
    {
      ignoreClass: 'my-ignore-extra-dropdown'
    }
  )

  const refHeading = useOnclickOutside(
    () => {
      setToggleOpenHeading(false)
    },
    {
      ignoreClass: 'my-ignore-heading'
    }
  )
  const refActionDropdown = useOnclickOutside(
    () => {
      setToggleOpenActionDropdown(false)
    },
    {
      ignoreClass: 'my-ignore-action-dropdown'
    }
  )
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    editor.chain().extendMarkRange('link').setLink({ href: url }).run()
  }, [editor])

  const onClickUpload: MouseEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.currentTarget.value = ''
    },
    []
  )

  if (!editor) {
    return null
  }

  const fileUUID = `file-${uuidV4()}`
  return (
    <>
      <div className="flex h-full items-center justify-center">
        <div className="relative">
          <Tooltip content={`${t('tooltip:menuToolbar:heading')}`}>
            <button
              type="button"
              className="my-ignore-heading flex items-center justify-center"
              onClick={() => setToggleOpenHeading(true)}
            >
              <IconWrapper size={14} name="Heading" />
              <IconWrapper size={14} name="ChevronDown" />
            </button>
          </Tooltip>

          {toggleOpenHeading ? (
            <div
              ref={refHeading}
              className="absolute left-0 top-full z-50 w-[150px]"
            >
              <div className="rounded bg-white shadow-ats outline-none dark:bg-gray-900 dark:shadow-dark-ats">
                <div className="flex flex-col items-start p-1">
                  <button
                    className="w-full px-1.5 py-2 text-left text-gray-900 hover:bg-gray-50"
                    type="button"
                    onClick={() => {
                      //@ts-expect-error
                      editor.chain().setParagraph().run()
                      setToggleOpenHeading(false)
                    }}
                  >
                    <p
                      className={`text-sm ${
                        editor.isActive('paragraph')
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-600 dark:text-gray-400'
                      } dark:text-gray-300`}
                    >
                      Regular
                    </p>
                  </button>
                  <button
                    type="button"
                    className="w-full px-1.5 py-2 text-left text-gray-900 hover:bg-gray-50"
                    onClick={() => {
                      //@ts-expect-error
                      editor.chain().toggleHeading({ level: 1 }).run()
                      setToggleOpenHeading(false)
                    }}
                  >
                    <p
                      className={`text-sm ${
                        editor.isActive('heading', { level: 1 })
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-600 dark:text-gray-400'
                      } dark:text-gray-300`}
                    >
                      Heading 1
                    </p>
                  </button>
                  <button
                    type="button"
                    className="w-full px-1.5 py-2 text-left text-gray-900 hover:bg-gray-50"
                    onClick={() => {
                      //@ts-expect-error
                      editor.chain().toggleHeading({ level: 2 }).run()
                      setToggleOpenHeading(false)
                    }}
                  >
                    <p
                      className={`text-sm ${
                        editor.isActive('heading', { level: 2 })
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-600 dark:text-gray-400'
                      } dark:text-gray-300`}
                    >
                      Heading 2
                    </p>
                  </button>
                  <button
                    type="button"
                    className="w-full px-1.5 py-2 text-left text-gray-900 hover:bg-gray-50"
                    onClick={() => {
                      //@ts-expect-error
                      editor.chain().toggleHeading({ level: 3 }).run()
                      setToggleOpenHeading(false)
                    }}
                  >
                    <p
                      className={`text-sm ${
                        editor.isActive('heading', { level: 3 })
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-600 dark:text-gray-400'
                      } dark:text-gray-300`}
                    >
                      Heading 3
                    </p>
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Tooltip content={`${t('tooltip:menuToolbar:bold')}`}>
        <button
          type="button"
          //@ts-expect-error
          onClick={() => editor.chain().toggleBold().run()}
          //@ts-expect-error
          disabled={!editor.can().chain().focus().toggleBold().run()}
        >
          <IconWrapper
            size={14}
            name="Bold"
            className={
              editor.isActive('bold')
                ? 'text-primary-400 dark:text-primary-400'
                : 'text-gray-600 dark:text-gray-400'
            }
          />
        </button>
      </Tooltip>
      <Tooltip content={`${t('tooltip:menuToolbar:italic')}`}>
        <button
          type="button"
          //@ts-expect-error
          onClick={() => editor.chain().toggleItalic().run()}
          //@ts-expect-error
          disabled={!editor.can().chain().focus().toggleItalic().run()}
        >
          <IconWrapper
            size={14}
            name="Italic"
            className={
              editor.isActive('italic')
                ? 'text-primary-400 dark:text-primary-400'
                : 'text-gray-600 dark:text-gray-400'
            }
          />
        </button>
      </Tooltip>
      <Tooltip content={`${t('tooltip:menuToolbar:underline')}`}>
        <button
          type="button"
          onClick={() => editor.chain().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
        >
          <IconWrapper
            size={14}
            name="Underline"
            className={
              editor.isActive('underline')
                ? 'text-primary-400 dark:text-primary-400'
                : 'text-gray-600 dark:text-gray-400'
            }
          />
        </button>
      </Tooltip>

      <Tooltip content={`${t('tooltip:menuToolbar:textColor')}`}>
        <div className="relative">
          <IconWrapper
            size={14}
            name="Palette"
            className={
              editor.isActive('color')
                ? 'text-primary-400 dark:text-primary-400'
                : 'text-gray-600 dark:text-gray-400'
            }
          />

          <input
            className="absolute left-0 top-0 w-full opacity-0 hover:cursor-pointer"
            type="color"
            onClick={(event) =>
              //@ts-expect-error
              editor.chain().setColor(event.target.value).run()
            }
            onChange={(event) => {
              editor.chain().setColor(event.target.value).run()
            }}
            data-testid="setColor"
          />
        </div>
      </Tooltip>
      <Tooltip content={`${t('tooltip:menuToolbar:insertLinks')}`}>
        <button type="button" onClick={setLink}>
          <IconWrapper
            size={14}
            name="Link"
            className={
              editor.isActive('link')
                ? 'text-primary-400 dark:text-primary-400'
                : 'text-gray-600 dark:text-gray-400'
            }
          />
        </button>
      </Tooltip>
      <Tooltip content={`${t('tooltip:menuToolbar:bulletedList')}`}>
        <button
          type="button"
          //@ts-expect-error
          onClick={() => editor.chain().toggleBulletList().run()}
        >
          <IconWrapper
            size={14}
            name="List"
            className={
              editor.isActive('bulletList')
                ? 'text-primary-400 dark:text-primary-400'
                : 'text-gray-600 dark:text-gray-400'
            }
          />
        </button>
      </Tooltip>
      <Tooltip content={`${t('tooltip:menuToolbar:numberedList')}`}>
        <button
          type="button"
          //@ts-expect-error
          onClick={() => editor.chain().toggleOrderedList().run()}
        >
          <IconWrapper
            size={14}
            name="ListOrdered"
            className={
              editor.isActive('orderedList')
                ? 'text-primary-400 dark:text-primary-400'
                : 'text-gray-600 dark:text-gray-400'
            }
          />
        </button>
      </Tooltip>

      {extraToolbar?.attachments?.show &&
      extraToolbar?.attachments.showPosition === 'menuToolbar' ? (
        <Tooltip content={`${t('tooltip:menuToolbar:attachFiles')}`}>
          <input
            name="file"
            id={fileUUID}
            type="file"
            multiple
            className="hidden"
            accept={extraToolbar?.attachments.acceptedFiles}
            onClick={onClickUpload}
            onChange={(event) => {
              event.preventDefault()
              const files = event.currentTarget ? event.currentTarget.files : []

              if (extraToolbar.attachments?.fileChange) {
                extraToolbar.attachments.fileChange(files)
              }
            }}
          />
          <label htmlFor={fileUUID} className="cursor-pointer">
            <IconWrapper
              size={14}
              name="Paperclip"
              className="text-gray-500 dark:text-gray-400"
            />
          </label>
        </Tooltip>
      ) : null}

      {extraToolbar?.attachmentsList?.length && (
        <div className="relative">
          <Tooltip content={`${t('tooltip:menuToolbar:attachFiles')}`}>
            <button
              type="button"
              className="my-ignore-extra-dropdown flex items-center"
              onClick={() => setToggleOpenAttachmentDropdown(true)}
            >
              <IconWrapper
                size={14}
                name="Paperclip"
                className="text-gray-600 dark:text-gray-400"
              />
            </button>
          </Tooltip>
          {toggleOpenAttachmentDropdown ? (
            <div
              ref={refExtraAttachmentDropdown}
              className="absolute left-0 top-full z-50 w-[188px] pt-2.5"
            >
              <div className="rounded bg-white shadow-ats outline-none dark:bg-gray-900 dark:shadow-dark-ats">
                <div className="flex  max-h-[338px] flex-col items-start overflow-y-auto p-1">
                  {extraToolbar?.attachmentsList?.map((attachItem) => {
                    const {
                      type,
                      show,
                      showPosition,
                      acceptedFiles,
                      fileChange
                    } = attachItem
                    return (
                      <div className="space-x-3w-full w-[100%] cursor-pointer rounded py-[6px] pl-2 pr-[10px] text-sm text-gray-900 hover:bg-gray-50">
                        {type === 'fromLocal' && (
                          <>
                            <input
                              name="file"
                              id={fileUUID}
                              type="file"
                              multiple
                              className="hidden"
                              accept={acceptedFiles}
                              onClick={onClickUpload}
                              onChange={(event) => {
                                event.preventDefault()
                                const files = event.currentTarget
                                  ? event.currentTarget.files
                                  : []

                                if (fileChange) {
                                  fileChange(files)
                                }
                              }}
                            />
                            <label
                              htmlFor={fileUUID}
                              className="cursor-pointer"
                            >
                              {attachItem.label}
                            </label>
                          </>
                        )}
                        {type === 'fromFiles' && (
                          <div onClick={attachItem.onClick}>
                            {' '}
                            {attachItem.label}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}

      {extraToolbar?.expand?.show ? (
        <Tooltip
          classNameConfig={{
            content: extraToolbar?.expand?.isActive ? 'hidden' : ''
          }}
          content={
            !extraToolbar?.expand?.isActive
              ? `${t('tooltip:menuToolbar:expand')}`
              : ''
          }
        >
          <button
            type="button"
            onClick={() => {
              if (extraToolbar?.expand?.onClick) {
                extraToolbar.expand.onClick()
              }
            }}
          >
            {extraToolbar?.expand?.isActive ? (
              <IconWrapper
                size={14}
                name="Minimize2"
                className="text-primary-400 dark:text-primary-400"
              />
            ) : (
              <IconWrapper
                size={14}
                name="MoveDiagonal"
                className="text-gray-600 dark:text-gray-400"
              />
            )}
          </button>
        </Tooltip>
      ) : null}
      {extraToolbar?.attachments?.show &&
      extraToolbar?.attachments.showPosition !== 'menuToolbar' ? (
        <input
          name="file"
          id={fileUUID}
          type="file"
          multiple
          className="hidden"
          accept={extraToolbar?.attachments.acceptedFiles}
          onClick={onClickUpload}
          onChange={(event) => {
            event.preventDefault()
            const files = event.currentTarget ? event.currentTarget.files : []

            if (extraToolbar.attachments?.fileChange) {
              extraToolbar.attachments.fileChange(files)
            }
          }}
        />
      ) : null}
      <div className="relative">
        <Tooltip content={`${t('tooltip:menuToolbar:moreOptions')}`}>
          <button
            type="button"
            className="my-ignore-action-dropdown flex items-center justify-center"
            onClick={() => {
              setToggleOpenActionDropdown(true)
            }}
          >
            <IconWrapper size={14} name="MoreVertical" />
          </button>
        </Tooltip>

        {toggleOpenActionDropdown ? (
          <div
            ref={refActionDropdown}
            className="absolute left-0 top-full z-50"
          >
            <div className="rounded bg-white shadow-ats outline-none dark:bg-gray-900 dark:shadow-dark-ats">
              <div className="flex space-x-4 p-2">
                <Tooltip content={`${t('tooltip:menuToolbar:alignLeft')}`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      editor.chain().setTextAlign('left').run()
                    }}
                  >
                    <IconWrapper
                      size={14}
                      name="AlignLeft"
                      className={
                        editor.isActive({ textAlign: 'left' })
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-600 dark:text-gray-400'
                      }
                    />
                  </button>
                </Tooltip>
                <Tooltip content={`${t('tooltip:menuToolbar:alignCenter')}`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      editor.chain().setTextAlign('center').run()
                    }}
                  >
                    <IconWrapper
                      size={14}
                      name="AlignCenter"
                      className={
                        editor.isActive({ textAlign: 'center' })
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-600 dark:text-gray-400'
                      }
                    />
                  </button>
                </Tooltip>
                <Tooltip content={`${t('tooltip:menuToolbar:alignRight')}`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      editor.chain().setTextAlign('right').run()
                    }}
                  >
                    <IconWrapper
                      size={14}
                      name="AlignRight"
                      className={
                        editor.isActive({ textAlign: 'right' })
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-600 dark:text-gray-400'
                      }
                    />
                  </button>
                </Tooltip>
                <Tooltip content={`${t('tooltip:menuToolbar:highlightText')}`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      editor.chain().toggleHighlight().run()
                    }}
                  >
                    <IconWrapper
                      size={14}
                      name="Highlighter"
                      className={
                        editor.isActive('highlight')
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-600 dark:text-gray-400'
                      }
                    />
                  </button>
                </Tooltip>
                <Tooltip content={`${t('tooltip:menuToolbar:quotes')}`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      editor.chain().toggleBlockquote().run()
                    }}
                  >
                    <IconWrapper
                      size={14}
                      name="Quote"
                      className={
                        editor.isActive('blockquote')
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-600 dark:text-gray-400'
                      }
                    />
                  </button>
                </Tooltip>
                <Tooltip content={`${t('tooltip:menuToolbar:clearFormat')}`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      editor.chain().unsetAllMarks().run()
                      editor.chain().clearNodes().run()
                    }}
                  >
                    <IconWrapper size={14} name="Eraser" />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div
        ref={mentionParentRef}
        className="flex flex-1 items-center justify-end"
      >
        <div className="flex items-center space-x-4">
          {extraToolbar?.AIWriter?.show ? (
            <div>
              <Popover
                open={openAIWriter}
                onOpenChange={toggleAIWriter}
                modal={extraToolbar?.AIWriter.modal}
              >
                <Tooltip content={`${t('form:tooltip_ai_writer')}`}>
                  <PopoverTrigger asChild>
                    <TextButton
                      iconMenus="Wand2"
                      label={
                        extraToolbar?.AIWriter?.isReplace
                          ? `${t('form:improve_AI')}`
                          : `${t('form:ai_writer')}`
                      }
                      size="sm"
                      underline={false}
                    />
                  </PopoverTrigger>
                </Tooltip>

                <PopoverPortal>
                  <PopoverContent
                    side="right"
                    align="start"
                    className="w-[480px] translate-x-[18px]"
                  >
                    <AIWriterWrapper
                      toggleAIWriter={toggleAIWriter}
                      onSubmit={extraToolbar?.AIWriter?.onSubmitAIWriter}
                      loadingGenerate={
                        extraToolbar?.AIWriter?.loadingGenerate || false
                      }
                      textGenerate={extraToolbar?.AIWriter?.textGenerate}
                      defaultValue={extraToolbar?.AIWriter?.defaultValue}
                      onRegenerate={extraToolbar?.AIWriter?.onRegenerate}
                      onSubmitAddSummary={
                        extraToolbar?.AIWriter?.onSubmitAddSummary
                      }
                      isReplaceAIWriter={extraToolbar?.AIWriter?.isReplace}
                    />
                  </PopoverContent>
                </PopoverPortal>
              </Popover>
            </div>
          ) : null}
          {extraToolbar?.AIWriterJob?.show ? (
            <div>
              <Popover
                open={openAIWriter}
                onOpenChange={toggleAIWriter}
                modal={extraToolbar?.AIWriterJob.modal}
              >
                <Tooltip
                  content={`${
                    extraToolbar?.AIWriterJob?.isDisabled
                      ? t('form:tooltip_disabled_ai_writer')
                      : ''
                  }`}
                >
                  <PopoverTrigger asChild>
                    <TextButton
                      iconMenus="Wand2"
                      label={
                        extraToolbar?.AIWriterJob?.isReplace
                          ? `${t('form:improve_AI')}`
                          : `${t('form:ai_writer')}`
                      }
                      size="sm"
                      underline={false}
                      isDisabled={extraToolbar?.AIWriterJob?.isDisabled}
                    />
                  </PopoverTrigger>
                </Tooltip>

                <PopoverPortal>
                  <PopoverContent
                    onOpenAutoFocus={(e) => e.preventDefault()}
                    side="right"
                    align="start"
                    className="w-[480px] translate-x-[18px]"
                  >
                    <AIWriterJobWrapper
                      toggleAIWriter={toggleAIWriter}
                      loadingGenerate={
                        extraToolbar?.AIWriterJob?.loadingGenerate || false
                      }
                      valueAIWriter={extraToolbar?.AIWriterJob?.valueAIWriter}
                      setValueAIWriter={
                        extraToolbar?.AIWriterJob?.setValueAIWriter
                      }
                      textGenerate={extraToolbar?.AIWriterJob?.textGenerate}
                      // onRegenerate={extraToolbar?.AIWriterJob?.onRegenerate}
                      onSubmitAIWriter={
                        extraToolbar?.AIWriterJob?.onSubmitAIWriter
                      }
                      onSubmitAddJD={extraToolbar?.AIWriterJob?.onSubmitAddJD}
                      isReplaceAIWriter={extraToolbar?.AIWriterJob?.isReplace}
                    />
                  </PopoverContent>
                </PopoverPortal>
              </Popover>
            </div>
          ) : null}
        </div>

        <div className="flex items-center space-x-4">
          {extraDropdownMenuEditor ? (
            <div className="flex items-center space-x-1">
              <div className="relative">
                <button
                  type="button"
                  className="my-ignore-extra-dropdown flex items-center"
                  onClick={() => setToggleOpenExtraDropdown(true)}
                >
                  {typeof extraDropdownMenuEditor.component.label === 'function'
                    ? extraDropdownMenuEditor.component.label(
                        toggleOpenExtraDropdown
                      )
                    : extraDropdownMenuEditor.component.label}
                </button>

                {toggleOpenExtraDropdown ? (
                  <div
                    ref={refExtraDropdown}
                    className="absolute right-0 top-full z-50 w-[188px] pt-2.5"
                  >
                    <div className="rounded bg-white shadow-ats outline-none dark:bg-gray-900 dark:shadow-dark-ats">
                      <div className="flex  max-h-[338px] flex-col items-start overflow-y-auto p-1">
                        {(extraDropdownMenuEditor.source || []).map(
                          (item, index) => (
                            <div
                              className="w-full cursor-pointer"
                              key={index}
                              onClick={() => {
                                if (extraDropdownMenuEditor.onClick) {
                                  extraDropdownMenuEditor.onClick({
                                    param: item,
                                    editor
                                  })
                                }
                                setToggleOpenExtraDropdown(false)
                              }}
                            >
                              <SelectOption
                                size="sm"
                                data={item}
                                isOption={true}
                                isHeading={false}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {extraDropdownMenuEditor.component.icon}
            </div>
          ) : null}
          {extraSelectTemplateButton}
        </div>
      </div>
    </>
  )
}

export const removeHTMLTagsRichEditor = (textWithHTMLTags: string) =>
  textWithHTMLTags.replace(/(<([^>]+)>)/g, '')

const RichEditor = ({
  toolbarPosition = 'top',
  variant = 'default',
  content,
  getElementAppendById,
  onChange,
  className = '',
  classNameWrapper = '',
  isDisabled = false,
  size = 'md',
  destructive = false,
  showCount = true,
  placeholder = '',
  limit = 10000,
  extraDropdownMenuEditor = undefined,
  editorRef,
  bubble = false,
  showToolbar = true,
  extraToolbar = undefined,
  autoFocus = false,
  editorMenuClassName = '',
  isNoDebounceOnChange = false,
  toggleAIWriter,
  openAIWriter,
  extraSelectTemplateButton
}: RichEditorProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptionsDebounced = useCallback(
    debounce(async (html: string) => {
      onChange && onChange(trimFirstContentBreakLine(html))
    }, 250),
    [onChange]
  )

  const getClassNameWrapper = () =>
    cn(
      editorVariants({
        variant,
        variantBorder:
          isDisabled === false || (isDisabled === true && destructive)
            ? 'default'
            : isDisabled === true && !destructive
            ? 'no-default'
            : 'none',
        size,
        disabled: isDisabled ? 'disabled' : 'default',
        destructive:
          variant === 'unstyled'
            ? 'none'
            : destructive
            ? 'destructive'
            : 'default',
        className:
          classNameWrapper ||
          className ||
          cn(
            'min-w-[602px] max-w-full min-h-[219px]',
            showToolbar ? '' : 'pt-3'
          ),
        mode: bubble ? 'bubble' : toolbarPosition
      })
    )

  const editor = useEditor({
    extensions: [
      // Document,
      // Bold,
      // Paragraph,
      // Text,
      Highlight.configure({
        multicolor: true,
        HTMLAttributes: {
          class: 'px-2 rounded-3 py-0.5 mark-attr'
        }
      }),
      Underline,
      // Image,
      // Dropcursor,
      // Gapcursor,
      // ListItem,
      Color,
      TextStyle,
      Link,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'center', 'right']
      }),
      Placeholder.configure({
        placeholder,
        emptyEditorClass: cn(
          editorPlaceholderVariants({
            size
          })
        )
      }),
      CharacterCount.configure({
        // limit
        // mode: 'nodeSize'
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'text-gray-900 font-semibold'
        },
        // @ts-expect-error
        renderLabel({
          options,
          node
        }: {
          options: { suggestion: { char: string } }
          node: { attrs: { label: string; id: string } }
        }) {
          return `${options.suggestion.char}${
            node.attrs.label ?? node.attrs.id
          }`
        },

        suggestion: extraToolbar?.mentions?.show
          ? {
              items: ({ query }: { query: string }) => {
                extraToolbar?.mentions?.suggestion?.onSearch &&
                  extraToolbar?.mentions?.suggestion?.onSearch(query)
                return []
              },
              render: () => {
                let component: any
                let popup: any
                const componentPropsEditor =
                  extraToolbar?.mentions?.suggestion?.componentPropsEditor || {}

                return {
                  onStart: (props: any) => {
                    if (extraToolbar?.mentions?.suggestion.component)
                      component = new ReactRenderer(
                        extraToolbar?.mentions?.suggestion.component,
                        {
                          props: {
                            ...props,
                            ...componentPropsEditor,
                            onChange: (value: ISelectOption) => {
                              extraToolbar?.mentions?.suggestion
                                ?.componentPropsEditor?.onChange &&
                                extraToolbar?.mentions?.suggestion?.componentPropsEditor?.onChange(
                                  value
                                )
                              props?.command({
                                label: value?.supportingObj?.name,
                                id: value?.value
                              })
                            }
                          },
                          editor: props.editor
                        }
                      )

                    if (!props.clientRect) {
                      return
                    }

                    //@ts-ignore
                    popup = tippy('body', {
                      getReferenceClientRect: props.clientRect,
                      appendTo: getElementAppendById
                        ? () => document.getElementById(getElementAppendById)
                        : 'parent',
                      content: component.element,
                      showOnCreate: true,
                      interactive: true,
                      trigger: 'manual',
                      placement: 'left',
                      strategy: 'fixed'
                    })
                    component.updateProps({
                      portalContainer: popup?.[0]?.popper
                    })
                  },

                  onUpdate(props: any) {
                    component.updateProps(props)

                    if (!props.clientRect) {
                      return
                    }

                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect
                    })
                  },

                  onKeyDown(props: any) {
                    if (props.event.key === 'Escape') {
                      popup[0].hide()

                      return true
                    }

                    return component.ref?.onKeyDown(props)
                  },

                  onExit() {
                    popup[0].destroy()
                    component.destroy()
                  }
                }
              }
            }
          : {}
      }),
      // @ts-expect-error
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        }
      }),
      Blockquote
    ],
    content: content ? content?.toString() : '',
    onUpdate: ({ editor }) => {
      // Update the local state with the content of the editor
      if (onChange) {
        if (isNoDebounceOnChange) {
          onChange && onChange(trimFirstContentBreakLine(editor.getHTML()))
        } else {
          loadOptionsDebounced(editor.getHTML())
        }
      }
    },
    autofocus: autoFocus ? 'end' : false,
    editable: !isDisabled,
    editorProps: {
      attributes: {
        spellcheck: 'false',
        class: getClassNameWrapper()
      }
    }
  })

  useEffect(() => {
    // handle change class in editorProps
    // issue link: https://github.com/ueberdosis/tiptap/pull/1540
    if (editor) {
      editor.setOptions({
        editorProps: {
          attributes: {
            class: getClassNameWrapper()
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classNameWrapper, className, isDisabled, destructive])

  useEffect(() => {
    if (editor && editorRef) {
      editorRef(editor)
    }
  }, [editor, editorRef])

  return (
    <div className={`relative ${className || 'min-w-[602px] max-w-full'}`}>
      {showToolbar && editor && bubble === false ? (
        <div
          className={cn(
            editorMenuVariants({
              variant:
                isDisabled === false || (isDisabled === true && destructive)
                  ? 'default'
                  : isDisabled === true && !destructive
                  ? 'no-default'
                  : 'none',
              disabled: isDisabled ? 'disabled' : 'default',
              toolbarPosition
            }),
            editorMenuClassName
          )}
        >
          <MenuEditor
            getElementAppendById={getElementAppendById}
            extraToolbar={extraToolbar}
            extraDropdownMenuEditor={extraDropdownMenuEditor}
            editor={editor}
            toggleAIWriter={toggleAIWriter}
            openAIWriter={openAIWriter}
            extraSelectTemplateButton={extraSelectTemplateButton}
          />
        </div>
      ) : null}
      {showToolbar && editor && bubble === true ? (
        <BubbleMenu
          className={cn(
            editorBubbleMenuVariants({
              variant:
                isDisabled === false || (isDisabled === true && destructive)
                  ? 'default'
                  : isDisabled === true && !destructive
                  ? 'no-default'
                  : 'none',
              disabled: isDisabled ? 'disabled' : 'default'
            })
          )}
          tippyOptions={{ duration: 100 }}
          editor={editor}
        >
          <MenuEditor
            getElementAppendById={getElementAppendById}
            extraToolbar={extraToolbar}
            extraDropdownMenuEditor={extraDropdownMenuEditor}
            editor={editor}
          />
        </BubbleMenu>
      ) : null}

      <EditorContent editor={editor} />
      {editor && showCount ? (
        <div
          className={cn(
            'absolute bottom-[1px] left-[1px] right-[1px] z-10 flex h-[22px] items-center justify-end px-3'
          )}
        >
          <p
            className={`text-sm ${
              isDisabled
                ? 'text-gray-400 dark:text-gray-700'
                : 'text-gray-600 dark:text-gray-400'
            }`}
          >
            {removeHTMLTagsRichEditor(editor?.getHTML()).length}/{limit}
          </p>
        </div>
      ) : null}
    </div>
  )
}

RichEditor.displayName = 'RichEditor'

export { MenuEditor, RichEditor }
export type {
  ExtraDropdownMenuEditorProps,
  IExtraToolbar,
  RichEditorProps,
  RichEditorSizeProps,
  IAttachmentsFile,
  IAttachmentsFiles,
  IAttachment
}
