import { format } from 'date-fns'

export const DATE_FORMAT_YYYYMMDD = 'yyyy-MM-dd'

export const changeTimezone = ({
  date,
  timezone
}: {
  date: string | Date
  timezone?: string
}): Date => {
  return new Date(
    new Date(date).toLocaleString('en-US', {
      timeZone: timezone
    })
  )
}

export const dayShortFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'EEE')
}

export const dayFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'do')
}

export const monthFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'MM')
}

export const yearFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'yyyy')
}

export const monthYearFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'MMM yyyy')
}

export const monthYearTemplateFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'yyyy/M')
}

export const monthDayFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'MMM do')
}

export const defaultFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'MMM do, yyyy')
}

export const defaultWeekFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'EEE MMM do, yyyy')
}

export const fullFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'MMM do, yyyy, HH:mm')
}

export const fullCustomFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, '- MMM do, yyyy - HH:mm')
}

export const timeFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'HH:mm')
}

export const timeFullFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'HH:mm:ss')
}

export const customDefaultFormatDate = (date: number | Date) => {
  if (!date) return ''
  return format(date, 'yyyy/M/d')
}

export const formatDateWithKey = (date: number | Date, formatKey?: string) => {
  if (!date) return ''
  return format(date, formatKey || 'yyyy/M/d')
}

export const showDateTime = ({
  start,
  end,
  timezone
}: {
  start?: string
  end?: string
  timezone?: string
}) => {
  if (!start || !end) return ''

  return `${fullFormatDate(
    changeTimezone({
      date: start,
      timezone
    })
  )} - ${timeFormatDate(
    changeTimezone({
      date: end,
      timezone
    })
  )} (GMT${
    new Date(end)
      .toLocaleString('en-US', {
        timeZone: timezone,
        timeZoneName: 'longOffset'
      })
      .match(/[-\+][0-9][0-9]:[0-9][0-9]/)?.[0] || ''
  })`
}

export const formatDateWithCustomSelect = (
  date: number | Date,
  dateStringKey: string
) => {
  if (!date) return ''
  if (dateStringKey === 'yyyy/MM/dd') {
    return format(date, 'yyyy/MM')
  }
  if (dateStringKey === 'dd/MM/yyyy') {
    return format(date, 'MM/yyyy')
  }
  if (dateStringKey === 'MM/dd/yyyy') {
    return format(date, 'MM/yyyy')
  }
  if (dateStringKey === 'dd/LLLL/yyyy') {
    return format(date, 'LLLL/yyyy')
  }
  if (dateStringKey === 'yyyy/LLLL/dd') {
    return format(date, 'yyyy/LLLL')
  }
  if (dateStringKey === 'LLLL/dd/yyyy') {
    return format(date, 'LLLL/yyyy')
  }
  return format(date, 'MMM yyyy')
}
