import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Children, isValidElement } from 'react'
import { IAttachmentsFile } from '~/core/ui/RichEditor'
import { LOCAL } from '~/core/constants/enum'

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
export function getValidChildren(children: React.ReactNode) {
  return Children.toArray(children).filter((child) =>
    isValidElement(child)
  ) as React.ReactElement[]
}

export function compact<T extends Record<any, any>>(object: T) {
  const clone = Object.assign({}, object)
  for (let key in clone) {
    if (clone[key] === undefined) delete clone[key]
  }
  return clone
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// @ts-expect-error
export function debounce(fn, delay = 250) {
  // @ts-expect-error
  let timeout

  // @ts-expect-error
  return (...args) => {
    // @ts-expect-error
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

export const delayMilliseconds = (ms: number) =>
  new Promise((res) => setTimeout(res, ms))

export const getDisabledSelectedCondition = ({
  isDisabled = false,
  isSelected = false
}: {
  isDisabled?: boolean
  isSelected?: boolean
}) => {
  if (isDisabled == false && isSelected == false) return 'noDisabledSelected'
  if (isDisabled == false && isSelected == true) return 'noDisabledWithSelected'
  if (isDisabled == true && isSelected == false) return 'disabledNoSelect'
  if (isDisabled == true && isSelected == true) return 'disabledWithSelect'

  return 'noDisabledSelected'
}

export const trimFirstContentBreakLine = (content?: string) => {
  if (!content) return ''
  if (content && content === '<p><br></p>')
    return content.replace('<p><br></p>', '')
  if (content && content === '<p></p>') return content.replace('<p></p>', '')
  return content
}

export function removeAccents(str: string): string {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}

export const uuidV4 = () => {
  // @ts-expect-error
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export const truncateTextWithDot = (text: string, textLength = 25) => {
  return text?.length > textLength ? `${text.slice(0, textLength)}...` : text
}

export const sumFilesSize = (
  list: Array<IAttachmentsFile & { profileId?: number }>
) => {
  let totalSavedFilesSize = 0
  list.forEach((file) => {
    let size = 0
    if (file.type !== LOCAL) {
      size = file?.size || 0
    } else {
      size = file?.file?.size || 0
    }
    totalSavedFilesSize = totalSavedFilesSize + size
  })
  return totalSavedFilesSize
}

export function removeChildObjects(array: any) {
  const parentIds = array.map((item: any) => item.id);
  
  return array.filter((item: any) => !parentIds.includes(item.parentId));
}