import { useQueryClient } from '@tanstack/react-query'
import { FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import configuration from '~/configuration'
import { Button } from '~/core/ui/Button'
import Empty from '~/core/ui/Empty'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { Skeleton } from '~/core/ui/Skeleton'
import { TypographyText } from '~/core/ui/Text'
import { Toggle } from '~/core/ui/Toggle'
import { cn } from '~/core/ui/utils'
import useNotificationsManagement from '~/lib/features/notifications/hooks/use-notifications-management'
import useNotificationStore from '~/lib/features/notifications/store'
import { INotification } from '~/lib/features/notifications/types'
import {
  NOTIFICATION_CANDIDATE_REJECT_INTERVIEW_TIME_SLOT,
  NOTIFICATION_USER_CANCEL_INTERVIEW,
  NOTIFICATION_USER_DELETE_COMPANY
} from '~/lib/features/notifications/utilities/enum'
import useBoundStore from '~/lib/store'
import InfiniteScroll from '../List/InfiniteScroll'
import SkeletonContainer from '../Skeleton'
import NotificationItem from './Event/NotificationItem'

const NotificationDrawerContainer: FC = () => {
  const { t } = useTranslation()
  const { user, openTaskDrawer, setToast } = useBoundStore()
  const {
    setOpenNotificationDrawer,
    notificationTotalUnreadCount,
    setNotificationTotalUnreadCount
  } = useNotificationStore()
  const { notificationPaging, filterControl, action, queryKey } =
    useNotificationsManagement()
  const queryClient = useQueryClient()
  const notificationListRef = useRef<HTMLDivElement | null>(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  const totalCount =
    notificationPaging?.data?.pages?.[0]?.notificationsList?.metadata
      ?.totalCount || 0

  const callbackRefetch = useCallback(
    async ({
      notificationItem,
      notificationType
    }: {
      notificationItem?: INotification
      notificationType: string
    }) => {
      if (notificationType === 'delete') {
        if (notificationItem?.id) {
          queryClient.setQueryData(
            [queryKey],
            (oldData?: {
              pages?: Array<{
                notificationsList: {
                  collection: Array<INotification>
                  metadata: {
                    totalCount: number
                  }
                }
              }>
            }) => {
              return {
                ...oldData,
                pages: oldData?.pages?.map(
                  (page: {
                    notificationsList: {
                      collection: Array<INotification>
                      metadata: {
                        totalCount: number
                      }
                    }
                  }) => ({
                    ...page,
                    notificationsList: {
                      ...page.notificationsList,
                      collection: page.notificationsList.collection.map(
                        (notification: INotification) => {
                          return {
                            ...notification,
                            isFEDeleted:
                              notificationItem.id === notification.id
                                ? true
                                : notification.isFEDeleted
                          }
                        }
                      ),
                      metadata: {
                        ...page.notificationsList.metadata,
                        totalCount:
                          page.notificationsList.metadata.totalCount - 1
                      }
                    }
                  })
                )
              }
            }
          )

          if (!notificationItem?.readAt) {
            const count =
              notificationTotalUnreadCount - 1 >= 0
                ? notificationTotalUnreadCount - 1
                : 0
            setNotificationTotalUnreadCount(count)
          }

          setToast({
            open: true,
            type: 'success',
            title: `${t('notification:notificationDeleted')}`
          })
        } else {
          queryClient.setQueryData(
            [queryKey],
            (oldData?: {
              pages?: Array<{
                notificationsList: {
                  collection: Array<INotification>
                  metadata: {
                    totalCount: number
                  }
                }
              }>
            }) => {
              return {
                ...oldData,
                pages: oldData?.pages?.map(
                  (page: {
                    notificationsList: {
                      collection: Array<INotification>
                      metadata: {
                        totalCount: number
                      }
                    }
                  }) => ({
                    ...page,
                    notificationsList: {
                      ...page.notificationsList,
                      collection: page.notificationsList.collection.map(
                        (notification: INotification) => {
                          return {
                            ...notification,
                            isFEDeleted: true
                          }
                        }
                      ),
                      metadata: {
                        ...page.notificationsList.metadata,
                        totalCount: 0
                      }
                    }
                  })
                )
              }
            }
          )

          setNotificationTotalUnreadCount(0)

          setToast({
            open: true,
            type: 'success',
            title: `${t('notification:allNotificationsDeleted')}`
          })
        }
      }

      if (notificationType === 'unRead') {
        if (notificationItem?.id) {
          queryClient.setQueryData(
            [queryKey],
            (oldData?: {
              pages?: Array<{
                notificationsList: {
                  collection: Array<INotification>
                  metadata: {
                    totalCount: number
                  }
                }
              }>
            }) => {
              return {
                ...oldData,
                pages: oldData?.pages?.map(
                  (page: {
                    notificationsList: {
                      collection: Array<INotification>
                      metadata: {
                        totalCount: number
                      }
                    }
                  }) => ({
                    ...page,
                    notificationsList: {
                      ...page.notificationsList,
                      collection: page.notificationsList.collection.map(
                        (notification: INotification) => {
                          return {
                            ...notification,
                            readAt:
                              notificationItem.id === notification.id
                                ? String(new Date())
                                : notification.readAt
                          }
                        }
                      )
                    }
                  })
                )
              }
            }
          )

          const count =
            notificationTotalUnreadCount - 1 >= 0
              ? notificationTotalUnreadCount - 1
              : 0
          setNotificationTotalUnreadCount(count)
        } else {
          queryClient.setQueryData(
            [queryKey],
            (oldData?: {
              pages?: Array<{
                notificationsList: {
                  collection: Array<INotification>
                }
              }>
            }) => {
              return {
                ...oldData,
                pages: oldData?.pages?.map(
                  (page: {
                    notificationsList: {
                      collection: Array<INotification>
                    }
                  }) => ({
                    ...page,
                    notificationsList: {
                      ...page.notificationsList,
                      collection: page.notificationsList.collection.map(
                        (notification: INotification) => {
                          return {
                            ...notification,
                            readAt: String(new Date())
                          }
                        }
                      )
                    }
                  })
                )
              }
            }
          )

          setNotificationTotalUnreadCount(0)
        }
      }
    },
    [notificationTotalUnreadCount, queryClient, queryKey]
  )

  const onFilterUnRead = useCallback(
    (value: boolean) => {
      filterControl.onChange({
        filterBy: value ? 'UnRead' : null
      })
    },
    [filterControl]
  )

  // ----- RENDERED ----- //

  const renderedTabs = () => {
    return (
      <div className="flex items-center justify-between border-b border-b-gray-100 px-6 py-2">
        <p className="text-lg font-medium text-gray-900">
          {t('common:sidebar:management:list:notifications')}
        </p>

        <div className="flex items-center space-x-4">
          <Toggle
            isChecked={filterControl.value?.filterBy === 'UnRead'}
            name="view-switch"
            onCheckedChange={onFilterUnRead}
            size="sm"
            text={`${t('common:notification:showUnread')}`}
            toggle="leading"
          />

          <div className="relative">
            <Button
              configurations="ghost"
              type="secondary"
              iconMenus="MoreHorizontal"
              className="w-full"
              size="sm"
              onClick={() => setOpenDropdown(!openDropdown)}
              showFocusRing={openDropdown}
            />

            {openDropdown ? (
              <div className="absolute right-0 top-[30px] z-[222] w-[200px] overflow-hidden rounded bg-white pb-px pt-px shadow-ats dark:bg-gray-800 dark:shadow-dark-ats">
                <div className="m-1">
                  {[
                    {
                      name: `${t('common:notification:markAllAsRead')}`,
                      iconMenus: 'CheckSquare' as LucideIconName,
                      isDisabled: notificationTotalUnreadCount === 0,
                      isLoading: action.update.isLoadingNotificationMarkAsRead,
                      onClick: async () => {
                        await action.update.notificationMarkAsRead({
                          id: undefined,
                          all: true
                        })
                        await callbackRefetch({
                          notificationItem: undefined,
                          notificationType: 'unRead'
                        })

                        setOpenDropdown(false)
                      }
                    },
                    {
                      name: `${t('common:notification:deleteAllNotification')}`,
                      iconMenus: 'Trash2' as LucideIconName,
                      isDisabled: totalCount === 0,
                      isLoading: action.delete.isLoadingNotification,
                      onClick: async () => {
                        await action.delete.notification({
                          id: undefined,
                          all: true
                        })
                        await callbackRefetch({
                          notificationItem: undefined,
                          notificationType: 'delete'
                        })

                        setOpenDropdown(false)
                      }
                    }
                    // {
                    //   name: `${t('common:notification:notificationSettings')}`,
                    //   iconMenus: 'Settings' as LucideIconName,
                    //   onClick: () => {
                    //     console.log('Settings')
                    //   }
                    // }
                  ].map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          item.isDisabled
                            ? `pointer-events-none opacity-50`
                            : ''
                        }
                        onClick={item.onClick}>
                        <div className="relative flex cursor-pointer items-center rounded hover:bg-gray-50 dark:hover:bg-gray-700">
                          <div className="flex-1">
                            <div className="flex-1 px-3 py-[9px] outline-none">
                              <div className="flex w-full justify-between">
                                <div className="flex w-full items-center space-x-3">
                                  <IconWrapper
                                    size={16}
                                    name={item.iconMenus}
                                    className="text-gray-500"
                                  />
                                  <span className="line-clamp-1 flex-1 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                                    {item.name}
                                  </span>
                                </div>

                                {item.isLoading ? (
                                  <IconWrapper
                                    size={16}
                                    name="Loader2"
                                    className="animate-spin text-gray-500"
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  const renderedEmpty = () => (
    <div className="flex h-[245px] flex-1 items-center justify-center px-6 py-6">
      <Empty
        type="empty-data"
        title={`${t('common:notification:empty:title')}`}
        description={`${t('common:notification:empty:description')}`}
      />
    </div>
  )

  const renderedContent = () => (
    <div className="flex flex-col justify-between">
      <div className="flex-1">
        {renderedTabs()}

        <SkeletonContainer
          useLoading={false}
          isFirstLoading={notificationPaging.data === undefined}
          classNameFirstLoading="mt-2 pb-3"
          renderCustomSkeleton={
            <>
              {[1, 2, 3, 4].map((index) => (
                <div
                  key={index}
                  className="flex items-center space-x-3 px-4 py-2">
                  <Skeleton className="h-8 w-8 min-w-[32px] rounded-full" />
                  <div className="w-full space-y-1">
                    <Skeleton className="h-3 w-2/3" />
                    <Skeleton className="h-2 w-full" />
                  </div>
                </div>
              ))}
            </>
          }>
          {totalCount === 0 ? (
            renderedEmpty()
          ) : (
            <div className="max-h-[calc(100vh_-_162px)] pb-3 pr-0.5 pt-2">
              <div
                ref={notificationListRef}
                className="h-full max-h-[calc(100vh_-_182px)] overflow-auto">
                <InfiniteScroll
                  classNameEndOfList="text-sm font-normal"
                  wrapperRef={notificationListRef}
                  loadData={() => notificationPaging.fetchNextPage()}
                  haveNext={!!notificationPaging.hasNextPage}
                  skeletonElement={
                    <div className="mt-2 pb-3">
                      {[1, 2, 3, 4].map((index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-3 px-4 py-2">
                          <Skeleton className="h-8 w-8 min-w-[32px] rounded-full" />
                          <div className="w-full space-y-1">
                            <Skeleton className="h-3 w-2/3" />
                            <Skeleton className="h-2 w-full" />
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  isShowEndLabel={false}>
                  {notificationPaging.data?.pages?.map((page) =>
                    page.notificationsList.collection.map(
                      (notification: INotification) => {
                        if (notification?.isFEDeleted) return null

                        return (
                          <div
                            key={`referral-item-${notification?.id}`}
                            className="flex items-center space-x-3 px-4 py-2">
                            <NotificationItem
                              isLoadingDeleted={
                                action.delete.isLoadingNotification
                              }
                              currentTenantName={String(
                                user.currentTenant?.slug
                              )}
                              notification={notification}
                              onClick={async () => {
                                if (!notification.readAt) {
                                  action.update.notificationMarkAsRead({
                                    id: notification.id,
                                    all: false
                                  })
                                }
                                await callbackRefetch({
                                  notificationItem: notification,
                                  notificationType: 'unRead'
                                })

                                if (
                                  ![
                                    NOTIFICATION_CANDIDATE_REJECT_INTERVIEW_TIME_SLOT,
                                    NOTIFICATION_USER_CANCEL_INTERVIEW,
                                    NOTIFICATION_USER_DELETE_COMPANY
                                  ].includes(notification.actionType)
                                ) {
                                  setOpenNotificationDrawer(false)
                                }
                              }}
                              onDelete={async () => {
                                await action.delete.notification({
                                  id: notification.id,
                                  all: false
                                })
                                await callbackRefetch({
                                  notificationItem: notification,
                                  notificationType: 'delete'
                                })
                              }}
                            />
                          </div>
                        )
                      }
                    )
                  )}
                  {!notificationPaging.hasNextPage &&
                    totalCount > configuration.defaultPageSize && (
                      <TypographyText className="mt-4 text-center text-sm font-normal text-gray-600">
                        {t('careers:thatAllJobs')}
                      </TypographyText>
                    )}
                </InfiniteScroll>
              </div>
            </div>
          )}
        </SkeletonContainer>
      </div>
    </div>
  )

  return (
    <>
      <div
        className={cn(
          'fixed left-2 top-2 max-h-[calc(100vh_-_64px)] min-h-[300px] w-[400px] rounded-lg bg-white shadow-[0px_0px_0px_1px_rgba(0,0,0,0.05),0px_4px_6px_-2px_rgba(0,0,0,0.05),0px_10px_15px_-3px_rgba(0,0,0,0.10)] tablet:ml-[61px] tablet:mt-[56px] tablet:max-h-[calc(100vh_-_72px)]',
          openTaskDrawer ? 'z-[49]' : 'z-[52]'
        )}>
        {renderedContent()}
      </div>

      <div
        onClick={(e) => setOpenNotificationDrawer(false)}
        className={cn(
          'fixed bottom-0 left-0 right-0 top-0 !mx-0',
          openTaskDrawer ? 'z-[48]' : 'z-[51]'
        )}
      />
    </>
  )
}

export default withQueryClientProvider(NotificationDrawerContainer)
