import { gql } from 'urql'

const QueryJobTitleList = gql`
  query ($limit: Int!, $page: Int!, $search: String) {
    jobTitlesList(limit: $limit, page: $page, search: $search) {
      collection {
        id
        name
      }
      metadata {
        totalCount
      }
    }
  }
`
export default QueryJobTitleList
