import { useEffect } from 'react'

interface EscapeHandlerOptions {
  isDropdownOpen: boolean
  closeDropdown: () => void
  closeModal: () => void
}

export const useEscapeHandler = ({
  isDropdownOpen,
  closeDropdown,
  closeModal
}: EscapeHandlerOptions) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (isDropdownOpen) {
          closeDropdown()
          e.stopPropagation()
        } else {
          closeModal()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isDropdownOpen, closeDropdown, closeModal])
}
