import type { NextApiResponse } from 'next'
import { setCookie } from 'nookies'
import { mappingCurrentTenantCookie } from '~/cookies/currentTenant'
import { mappingUserCookie } from '~/cookies/user'
import { mappingTenantsCookie } from '../../cookies/tenants'
import { ITenant, ITenantDetail, IUserInformation } from '../@types/global'
import {
  COOKIE_PATH,
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_CURRENT_TENANT_EXT,
  SESSION_COOKIE_EXT,
  SESSION_COOKIE_IP,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_ROLE_EXT,
  SESSION_COOKIE_TENANTS,
  SESSION_COOKIE_USER,
  SESSION_ERROR_MESSAGE,
  SESSION_EXPIRES_AT_COOKIE_NAME
} from '../constants/cookies'
import { removeAttributesFromServer } from '../utilities/common'

export function setSessionCookieClient(
  cookieName: string,
  cookieValue: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH
  }

  setCookie(null, cookieName, cookieValue, options)
}

export function setSessionCookieExtClient(
  cookieName: string,
  cookieValue: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH,
    secure: true,
    sameSite: 'Lax'
  }

  setCookie(null, cookieName, cookieValue, options)
}

export async function saveSessionCookie(
  res: NextApiResponse,
  jsonFormat: {
    token: string
    user?: {
      data?: {
        attributes?: {
          tenants?: {
            data: Array<{ attributes: ITenant }>
          }
          roles?: { data: Array<{ attributes: { id: string; name: string } }> }
          userTenants?: {
            data?: Array<{ attributes: { userKind: string } }>
          }
        }
      }
    }
    currentTenant?: {
      data: {
        attributes: ITenantDetail
      }
    }
    errors?: Array<{
      message: string
      extensions: {
        error_code: string
        http_code: number
        errors: Array<{
          field: string
          message: string
        }>
      }
    }>
  },
  expiresIn: number,
  extraParams: {
    ip?: string | string[]
  }
) {
  if (jsonFormat?.errors && jsonFormat?.errors?.length > 0) {
    await setSessionCookie(
      res,
      SESSION_ERROR_MESSAGE,
      JSON.stringify({ errors: jsonFormat?.errors }).toString(),
      expiresIn
    )
  } else {
    if (extraParams.ip) {
      await setSessionCookie(
        res,
        SESSION_COOKIE_IP,
        String(extraParams.ip),
        expiresIn
      )
    }

    await setSessionCookie(
      res,
      SESSION_COOKIE_NAME,
      jsonFormat.token,
      expiresIn
    )
    await setSessionCookieExt(
      res,
      SESSION_COOKIE_EXT,
      jsonFormat.token,
      expiresIn
    )

    const user = {
      ...(jsonFormat?.user?.data?.attributes || {}),
      userTenants:
        jsonFormat?.user?.data?.attributes?.userTenants?.data &&
        jsonFormat?.user?.data?.attributes?.userTenants?.data.length > 0
          ? [
              {
                userKind: (jsonFormat?.user?.data?.attributes || {})
                  ?.userTenants?.data?.[0]?.attributes?.userKind
              }
            ]
          : []
    } as unknown as IUserInformation
    await setSessionCookie(
      res,
      SESSION_COOKIE_USER,
      JSON.stringify(mappingUserCookie(user)),
      expiresIn
    )

    await setSessionExpiryCookie(res, SESSION_EXPIRES_AT_COOKIE_NAME, expiresIn)

    const rolesObj = {
      data: removeAttributesFromServer(
        jsonFormat?.user?.data?.attributes?.roles?.data || []
      )
    }
    const currentRoleFilter = rolesObj.data.filter((item) => item?.id)
    const currentRoleName = currentRoleFilter.length
      ? currentRoleFilter?.[0]?.name
      : ''
    await setSessionCookieExt(
      res,
      SESSION_COOKIE_ROLE_EXT,
      JSON.stringify(currentRoleName).toString(),
      expiresIn
    )

    await setSessionCookie(
      res,
      SESSION_COOKIE_TENANTS,
      JSON.stringify(
        mappingTenantsCookie(
          removeAttributesFromServer(
            jsonFormat?.user?.data?.attributes?.tenants?.data || []
          )
        )
      ).toString(),
      expiresIn
    )

    if (jsonFormat?.currentTenant?.data) {
      const currentTenant = mappingCurrentTenantCookie(
        jsonFormat.currentTenant.data?.attributes
      )

      await setSessionCookie(
        res,
        SESSION_COOKIE_CURRENT_TENANT,
        JSON.stringify(currentTenant).toString(),
        expiresIn
      )
      await setSessionCookieExt(
        res,
        SESSION_COOKIE_CURRENT_TENANT_EXT,
        JSON.stringify(currentTenant).toString(),
        expiresIn
      )
    }
  }
}

function setSessionCookie(
  res: NextApiResponse,
  cookieName: string,
  cookieValue: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH
  }

  // when the session-cookie gets created
  // we store it as an httpOnly cookie (important!)
  setCookie({ res }, cookieName, cookieValue, options)
}

function setSessionCookieExt(
  res: NextApiResponse,
  cookieName: string,
  cookieValue: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH,
    httpOnly: false,
    secure: true,
    sameSite: 'Lax'
  }

  // when the session-cookie gets created
  // we store it as an httpOnly cookie (important!)
  setCookie({ res }, cookieName, cookieValue, options)
}

function setSessionExpiryCookie(
  res: NextApiResponse,
  cookieName: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH
  }

  // when the session-cookie gets created
  // we store it as an httpOnly cookie (important!)
  setCookie({ res }, cookieName, expiresIn.toString(), options)
}
