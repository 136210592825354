import { useMemo, useState, useEffect } from 'react'
import {
  Control,
  FieldErrors,
  FormState,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue
} from 'react-hook-form'
import { FormControlItem } from '~/core/ui/FormControlItem'
import {
  ICandidateApplicant,
  IDisabledFieldsEmailForm,
  ITemplateType
} from '~/lib/features/candidates/types'
import {
  IEmailResponseType,
  IEmailTemplate
} from '~/lib/features/settings/email-templates/types'
import EmailContentEditor, { IEmailForm } from './EmailContentEditor'
import InputEmailCandidateControl from './InputEmailCandidateControl'
import { IPromiseSearchOption } from '~/core/ui/Select'
import { IExtraToolbar } from '~/core/ui/RichEditor'
import { AlertCircleFill } from '~/core/ui/FillIcons'
import IconWrapper from '~/core/ui/IconWrapper'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import { IconButton } from '~/core/ui/IconButton'
import { TypographyText } from '~/core/ui/Text'
import { IAttachmentsFiles, IAttachment } from '~/core/ui/RichEditor'
import { ISelectOption } from '~/core/@types/global'

interface EmailEditor {
  autoFocus?: boolean
  formState: FormState<IEmailForm>
  control: Control
  isShowComposeEmail: boolean
  setValue: UseFormSetValue<IEmailForm>
  setError: UseFormSetError<IEmailForm>
  clearErrors: UseFormClearErrors<IEmailForm>
  isLoadingUpdateEmailProfile: boolean
  templateType?: ITemplateType
  placeholderValue?: { [key: string]: string }
  templatePlaceholderValue?: { [key: string]: string }
  onSubmitUpdateEmailProfile: (params: { email: string }) => Promise<void>
  defaultTemplateEmail?: IEmailTemplate
  defaultShowCc?: boolean
  triggerSetDefaultTemplate?: boolean
  disabledFields?: IDisabledFieldsEmailForm
  label?: string
  labelRequired?: boolean
  showFields?: Array<string>
  applicableJobs?: Array<ICandidateApplicant>
  promiseJobsOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  fieldsName?: {
    [key: string]: string
  }
  promiseEmailTemplates?: (
    params?: IPromiseSearchOption
  ) => Promise<IEmailResponseType>
  extraToolbar?: IExtraToolbar
  optionsToSearch?: ISelectOption[]
  disableSearch?: boolean
  emailTemplatesPosition?: 'subjectRow' | 'menuToolbar'
}

const EmailEditor = ({
  autoFocus,
  control,
  setError,
  setValue,
  formState,
  clearErrors,
  templateType,
  placeholderValue,
  templatePlaceholderValue,
  isShowComposeEmail,
  onSubmitUpdateEmailProfile,
  isLoadingUpdateEmailProfile,
  defaultTemplateEmail,
  defaultShowCc,
  triggerSetDefaultTemplate = true,
  disabledFields,
  label = 'Email content',
  labelRequired = true,
  showFields = [
    'to',
    'subject',
    'cc',
    'bcc',
    'jobId',
    'emailTemplate',
    'htmlBody'
  ],
  applicableJobs,
  promiseJobsOptions,
  promiseEmailTemplates,
  fieldsName = {
    emailTemplate: 'emailTemplate',
    htmlBody: 'htmlBody',
    subject: 'subject',
    to: 'to',
    cc: 'cc',
    bcc: 'bcc'
  },
  extraToolbar,
  optionsToSearch,
  disableSearch,
  emailTemplatesPosition
}: EmailEditor) => {
  // const renderErrors: string = useMemo(() => {
  //   let listError = []
  //   if (formState.errors && !!formState.errors.htmlBody) {
  //     listError.push(formState.errors.htmlBody?.message)
  //   }
  //   if (formState.errors && !!formState.errors.subject) {
  //     listError.push(formState.errors.subject?.message)
  //   }
  //   return listError.join(', ')
  // }, [formState])
  const [listAttachment, setListAttachment] = useState<IAttachment[]>([])

  const errorsList = useMemo(
    () =>
      Object.keys(formState.errors || {}).reduce((result, errorFieldName) => {
        return Object.values(fieldsName || {}).includes(errorFieldName)
          ? {
              ...result,
              [errorFieldName]:
                (formState?.errors || {})?.[
                  errorFieldName as keyof IEmailForm
                ] || ''
            }
          : result
      }, {} as FieldErrors<IEmailForm>),
    [formState, fieldsName]
  )

  useEffect(() => {
    if (extraToolbar?.attachments) {
      setListAttachment(() => [extraToolbar?.attachments as IAttachment])
    }
  }, [extraToolbar?.attachments])

  useEffect(() => {
    if (extraToolbar?.attachmentsList?.length) {
      setListAttachment(() => [...(extraToolbar?.attachmentsList || [])])
    }
  }, [extraToolbar?.attachmentsList])

  return (
    <>
      {!isShowComposeEmail ? (
        <InputEmailCandidateControl
          autoFocus
          onSubmitUpdateEmailProfile={onSubmitUpdateEmailProfile}
          isLoadingUpdateEmailProfile={isLoadingUpdateEmailProfile}
        />
      ) : (
        <>
          <FormControlItem
            label={label}
            labelRequired={labelRequired}
            destructive={
              errorsList &&
              Object.values(errorsList)
                ?.map((err) => err.message)
                .filter((errMessage) => !!errMessage)
                .join(', ').length > 0
            }
            destructiveText={
              errorsList &&
              Object.values(errorsList)
                ?.map((err) => err.message)
                .filter((errMessage) => !!errMessage)
                .join(', ')
            }>
            <EmailContentEditor
              autoFocus={autoFocus}
              setValue={setValue}
              setError={setError}
              formState={formState}
              control={control}
              templateType={templateType}
              placeholderValue={placeholderValue}
              templatePlaceholderValue={templatePlaceholderValue}
              defaultTemplateEmail={defaultTemplateEmail}
              defaultShowCc={defaultShowCc}
              triggerSetDefaultTemplate={triggerSetDefaultTemplate}
              clearErrors={clearErrors}
              disabledFields={disabledFields}
              showFields={showFields}
              applicableJobs={applicableJobs}
              promiseJobsOptions={promiseJobsOptions}
              promiseEmailTemplates={promiseEmailTemplates}
              fieldsName={fieldsName}
              extraToolbar={extraToolbar}
              optionsToSearch={optionsToSearch}
              disableSearch={disableSearch}
              emailTemplatesPosition={emailTemplatesPosition}
            />
          </FormControlItem>
          {(extraToolbar?.attachments?.list || [])?.length > 0 && (
            <div className="mt-2 grid gap-y-2">
              {(extraToolbar?.attachments?.list || []).map((item, index) => (
                <div
                  key={`file-${index}`}
                  className="flex flex-col rounded bg-gray-50 px-3 py-2">
                  <div className="flex items-center">
                    <div className="w-[16px]">
                      {item.status === 'error' ? (
                        <AlertCircleFill className="fill-red-500" />
                      ) : (
                        <IconWrapper
                          className="text-gray-400 dark:text-gray-400"
                          name="Paperclip"
                          size={16}
                        />
                      )}
                    </div>

                    <Tooltip
                      align="start"
                      classNameAsChild={cn(
                        'truncate block flex-1 text-sm font-medium text-gray-700 px-2',
                        extraToolbar?.attachments?.classNameItem
                      )}
                      content={item.name}>
                      {item.name}
                    </Tooltip>

                    <IconButton
                      type="secondary-destructive"
                      size="xs"
                      iconMenus="Trash2"
                      onClick={() => {
                        if (extraToolbar?.attachments?.onDelete) {
                          extraToolbar?.attachments?.onDelete({
                            id: item.id,
                            index
                          })
                        }
                      }}
                    />
                  </div>
                  {item.status === 'error' ? (
                    <TypographyText className="px-[24px] text-xs text-red-500">
                      {item.statusDescription}
                    </TypographyText>
                  ) : null}
                </div>
              ))}
            </div>
          )}
          {listAttachment?.length > 0 &&
            listAttachment.map((attachment) => {
              const { list, classNameItem, onDelete } = attachment
              if (list?.length) {
                return (
                  <div
                    key={`files${attachment.type}`}
                    className="mt-2 grid gap-y-2">
                    {list.map((item, index) => (
                      <div
                        key={`file-${index}`}
                        className="flex flex-col rounded bg-gray-50 px-3 py-2">
                        <div className="flex items-center">
                          <div className="w-[16px]">
                            {item.status === 'error' ? (
                              <AlertCircleFill className="fill-red-500" />
                            ) : (
                              <IconWrapper
                                className="text-gray-400 dark:text-gray-400"
                                name="Paperclip"
                                size={16}
                              />
                            )}
                          </div>

                          <Tooltip
                            align="start"
                            classNameAsChild={cn(
                              'truncate block flex-1 text-sm font-medium text-gray-700 px-2',
                              classNameItem
                            )}
                            content={item.name}>
                            {item.name}
                          </Tooltip>

                          <IconButton
                            type="secondary-destructive"
                            size="xs"
                            iconMenus="Trash2"
                            onClick={() => {
                              if (onDelete) {
                                onDelete({
                                  id: item.id,
                                  index
                                })
                              }
                            }}
                          />
                        </div>
                        {item.status === 'error' ? (
                          <TypographyText className="px-[24px] text-xs text-red-500">
                            {item.statusDescription}
                          </TypographyText>
                        ) : null}
                      </div>
                    ))}
                  </div>
                )
              }

              return null
            })}
        </>
      )}
    </>
  )
}

export default EmailEditor
